import React from 'react'
import { NavLink } from 'react-router-dom'
import crinsta from "./images/cr-insta.jpg"

const Services = () => {
    return (
        <>
            <h3 className='mt-4'> <b>  Social Media Platform </b></h3>
            <img src={{crinsta}} alt='uuu'/>
            <div class="accordion accordion-flush mt-5" id="accordionFlushExample"> 
                {/* <div className="container"> */}
                    <img src={{crinsta}} alt='uuu' /> 
                {/* </div> */}
                <div class="p-3 mb-3 bg-body-tertiary rounded center">
                    <NavLink to="/CRmedia" style={{ color: "white" }}> <b>Mr Chandan Rana Social Media</b></NavLink>
                </div>


                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingTwo">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            <h5> <b>Mr Sonu Sharma Social Media </b></h5> 
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ flexDirection: "column" }} >


                            <div class="p-3 mb-3 bg-body-tertiary rounded center">
                                <NavLink to="/SSmedia" style={{ color: "white" }}> <b>Mr Sonu Sharma Social Media</b></NavLink>
                            </div>
                        </div> </div>
                    </div>
                </div>


                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h5> <b> Team of Chandan Rana Social Media </b></h5>
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ flexDirection: "column" }} >
                            <div class="p-3 mb-3 bg-body-tertiary rounded center">
                                <NavLink to="/Teammedia" style={{ color: "white" }}> <b>Team of Chandan Rana </b></NavLink>
                            </div>
                        </div> </div>
                    </div>
                </div>


            </div>
            {/* <div class="shadow-lg p-3 mb-5 bg-body-tertiary" style={{ height: "3vh", backgroundColor: "" }} >
                <h2 className="fs-prospecting shdw" style={{ lineHeight: "1.6em", textAlign: "center", color: "aqua" }}><NavLink to="/CRmedia" style={{ backgroundColor: "blue", color: "aqua" }}> <h4><b>Only our Personal Team Profile</b></h4></NavLink><br />

                </h2>
            </div> */}



        </>
    )
}

export default Services     