import { NavLink } from 'react-router-dom'
import Supplement from "./images/supplement.jpeg"
import agri from "./images/agri.jpeg"
import exercise from "./images/exercise.jpeg"
import done from "./images/done.jpeg"
import order from "./images/order.png"
// import support from "./images/support.jpeg"
import img3 from "./images/img3.jpg"
import "./login/login2.css"
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../context/notes/noteContext';
import "./css/home.css"
import "./login/login2.css"
import Notes from "./Notes"
import ContactN from './ContactN'
// import wts from "./images/wts.jpeg" 
// import Supplement from "./images/supplement.jpeg" 
import warm from "./images/warm.jpeg"
import agriculture from "./images/agriculture.jpeg"
import healthproblem from "./images/healthproblem.png"
import support from "./images/support.png"
import online from "./images/online.jpeg"
import Services from './Services'
import cold from "./images/cold.jpeg"
import thought1 from "./images/thought1.jpeg"
import thought2 from "./images/thought2.jpeg"
import chandan from "./images/chandan.jpg"
import plan1 from "./images/plan1.jpg"
import plan2 from "./images/plan2.jpg"
import wts from "./images/wts.jpg"
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import tcr from "./images/tcr.jpg"




const Home = (props) => {

    const context = useContext(noteContext);
    let navigate = useNavigate();
    const { getNotes } = context;
    useEffect(() => {
        if (localStorage.getItem('token')) {
            alert = "logged in";
            getNotes()
            // return toast('Login Successfully Done !');

        }
        else {
            navigate("/login");
        }

    }, [])
    const showAlert = props
    return (
        <>

            <div className='homeki'>
                <NavLink to="https://wa.link/ii5qvn" style={{ color: "blue" }}>   <img src={tcr} class="" alt="..." /></NavLink>
            </div>

            <div className='wrwap'>
                <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-home mt-5"><h3 style={{ color: "aqua" }}></h3>
                    <NavLink to="/Offlineplan"
                        style={{
                            boxShadow: "white 3px 5px 9px",
                            height: "8vh",
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white"
                            // textDecoration: "none"
                        }}

                    > <h5 className='paperplan'><b style={{ color: "white" }}>Short Paper Plan</b></h5><br /><br /></NavLink>


                    <NavLink to="/WorkRoadmap" 
                        style={{
                            boxShadow: "white 3px 5px 9px",
                            height: "8vh",
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center", 
                            color: "white"
                            // textDecoration: "none"
                        }}

                    > <h5 className='paperplan'><b style={{ color: "white" }}>Work Roadmap </b></h5><br /><br /></NavLink>



                </div>

                <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-home mt"><h3 style={{ color: "white", flexDirection: "column" }}></h3>
                    <NavLink to="https://prev.myvestige.com/Images/DownloadPdfFile/E-Catalogue/English/42/"
                        style={{
                            boxShadow: "white 3px 5px 9px",
                            height: "8vh",
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white"
                            // textDecoration: "none"
                        }}

                    > <h5 className='paperplan'><b style={{ color: "white" }}> Product Book</b></h5><br /><br /></NavLink>


                    <NavLink to="/Teamdata" 
                        style={{
                            boxShadow: "white 3px 5px 9px",
                            height: "8vh",
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white"
                            // textDecoration: "none"
                        }}

                    > <h5 className='paperplan'><b style={{ color: "white" }}> Add Work </b></h5><br /><br /></NavLink>

                    <hr style={{ backgroundColor: "white" }} />
                    {/* <Notes showAlert={showAlert} /> */}
                    {/* <div className='register-link2 id-account-height' style={{ marginBottom: "4em", fontSize: "1.3em" }}>
                    <button className='id-account-height' style={{
                        width: "86%",

                        borderRadius: "8px",
                        backgroundColor: "rgb(50 104 5)"
                    }}><NavLink to="/Reg" style={{ color: "white" }}> Add Your Team Id - Account </NavLink></button>
                </div> */}





                </div>

                <hr style={{ backgroundColor: "white" }} />
                <hr style={{ backgroundColor: "white" }} />



                <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-home mt-5"><h3 style={{ color: "black" }}></h3>
                    <NavLink to="/Warm"
                        style={{
                            boxShadow: "white 3px 5px 9px",
                            height: "8vh",
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white"
                            // textDecoration: "none"
                        }}

                    > <h5 className='paperplan'><b style={{ color: "white" }}>Warm Market</b></h5><br /><br /></NavLink>


                    <NavLink to=""
                        style={{
                            boxShadow: "white 3px 5px 9px",
                            height: "8vh",
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white"
                            // textDecoration: "none"
                        }}

                    > <h5 className='paperplan'><b style={{ color: "white" }}>Trainings - TCR</b></h5><br /><br /></NavLink>



                </div>

                <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-home mt"><h3 style={{ color: "white", flexDirection: "column" }}></h3>
                    <NavLink to="/HealthSupplement"
                        style={{
                            boxShadow: "white 3px 5px 9px",
                            height: "8vh",
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white"
                            // textDecoration: "none"
                        }}

                    > <h5 className='paperplan'><b style={{ color: "white" }}> Health Problem </b></h5><br /><br /></NavLink>


                    <NavLink to="/Supplement"
                        style={{
                            boxShadow: "white 3px 5px 9px",
                            height: "8vh",
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white"
                            // textDecoration: "none"
                        }}

                    > <h5 className='paperplan'><b style={{ color: "white" , alignItems:"center"}}> Supplements </b></h5><br /><br /></NavLink>

                    <hr style={{ backgroundColor: "white" }} />
                    {/* <Notes showAlert={showAlert} /> */}
                    {/* <div className='register-link2 id-account-height' style={{ marginBottom: "4em", fontSize: "1.3em" }}>
                    <button className='id-account-height' style={{
                        width: "86%",

                        borderRadius: "8px",
                        backgroundColor: "rgb(50 104 5)"
                    }}><NavLink to="/Reg" style={{ color: "white" }}> Add Your Team Id - Account </NavLink></button>
                </div> */}





                </div>


            </div>

            <div id="carouselExampleDark" className="carousel carousel-dark slide mt-4" data-bs-ride="carousel">
                <div className="carousel-indicators" >
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner" style={{ boxShadow: "white 4px -3px 9px" }}>
                    <div className="carousel-item active" data-bs-interval="2000">
                        <img src={img3} className="d-block w-100 img-home" alt="..." />
                        <div className="carousel-caption d-none d-md-block">
                            <h5 style={{ color: "white" }}>First slide label</h5>
                            {/* <p style={{ color: "white" }}>Some representative placeholder content for the first slide.</p> */}
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <img src={thought1} className="d-block w-100 img-home" alt="..." />
                        <div className="carousel-caption d-none d-md-block">
                            <h5 style={{ color: "white" }}>Second slide label</h5>
                            {/* <p style={{ color: "white" }}>Some representative placeholder content for the second slide.</p> */}
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <img src={thought2} className="d-block w-100 img-home" alt="..." />
                        <div className="carousel-caption d-none d-md-block">
                            <h5 style={{ color: "white" }}>Second slide label</h5>
                            {/* <p style={{ color: "white" }}>Some representative placeholder content for the second slide.</p> */}
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>


            <ContactN />


            <hr />
            {/* <ToastContainer />  */}

        </>
    )
}

export default Home
