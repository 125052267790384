import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { FaEnvelope, FaLock } from 'react-icons/fa'
import { FaSignalMessenger } from 'react-icons/fa6'
import Spinner from './Spinner'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


const Signup = (props) => {

    // const success = () =>{
    //     toast("Login successfullt");
    // }
    // const failed = () =>{
    //     toast("Login Failed");
    // }


    const notify = () => toast("Vestige id is Your Password");

    const [credentials, setCredentials] = useState({ name: "", email: "", password: "" })
    let navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, password } = credentials;
        const response = await fetch("https://inotebook-gqci.onrender.com/api/Auth/createuser", {

            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email, password })
        }); 
        const json = await response.json() 
        console.log(json) 

        if (json.success) {
            //save the authtoken and redirect 
            localStorage.setItem('token', json.authtoken);
            // { loading && <Spinner /> }
            navigate("/HomeN"); 
            // props.showAlert("Successfully ! Created Your Account ", "success")

            return toast('Account Created Successfully');
            // alert("This is Your Id & psndf", "success")
        }
        else {
            // props.showAlert("Entered Duplicate Vestige ID", "Danger") 


            // alert("Entered Duplicate Vestige ID", "Danger") 
            return toast('Id Already Exists in Our System');


        }
    }
    const onchange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    return (

        <>
            <h3 style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "aqua" }}>Make Sure, You Are Part of Team Chandan Rana</h3>

            <div className='container-nb' style={{ backgroundColor: "tranparent", border: "display-block" }}>
                <div className='form-box2-reg' style={{ color: "white" }}>
                    <form action='' onSubmit={handleSubmit} loader={<Spinner />} >
                        <h1 className="mt-5" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><ul>Registeration</ul></h1>

                        <div className='input-box2'>
                            <input type='text' placeholder=" Enter Your Name" id="name" name='name' onChange={onchange} required />
                            <FaSignalMessenger className='icon2' />
                        </div>

                        <div className='input-box2'>
                            <input type='number' placeholder=" Enter Mobile No. / Vestige Id " id="email" name='email' onChange={onchange} required />
                            {/* <FaEnvelope className='icon2' />  */}
                        </div>

                        <div className='input-box2'>
                            <input type='password' placeholder=' Enter Your Vestige id No.' id="password" name='password' onChange={onchange} minLength={5} required />
                            <FaLock className='icon2' />
                        </div>


                        {/* <div className='remember-forgot2'>
                            <label><input type='checkbox' required /> I agree to the terms  & conditions</label>
                        </div> */}
                        <button className='btn0 mt-5' type='submit' onClick={notify}>Signup</button><br />
                        <div className='register-link2'>
                            <p>Already have an account <NavLink to="/HomeN" onClick={notify} style={{ color: "#80ff32" }}>Login</NavLink></p>
                        </div>

                    </form>
                </div>
            </div>
            <ToastContainer />

        </>
    )
}

export default Signup